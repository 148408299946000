import { post } from './general'

export const signIn = (body) => {
  return post('/user/signin', {}, body)
}

export const signUp = (body) => {
  return post('/user/signup', {}, body)
}

// export const unlockSession = (body) => {
//   return post(
//     '/user/session/unlock',
//     {},
//     body,
//   )
// }

export const logout = () => {
  return post('/user/signout', {}, {})
}
