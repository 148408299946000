import React, { useState, useEffect } from 'react'
import { getAliExpressOAuth2 } from 'utils/api/internal-apps'

const AliExpressOAuthCallback = () => {
  // Get code from query parameters
  const search = window.location.search
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const state = params.get('state')

  const [saving, setSaving] = useState(false)

  const onRunStep2OfOAuth = () => {
    if (saving) {
      return
    }

    setSaving(true)

    getAliExpressOAuth2({ code, state }).then((response) => {
      if (response.success) {
        window.location.href = '/?success=ali-express-connected'
      } else {
        window.alert(response.data.message)
      }
    })
  }

  useEffect(() => {
    onRunStep2OfOAuth()
  }, [])

  if (!code || !state) {
    return (
      <div>
        <p>Invalid request</p>
      </div>
    )
  }

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  )
}

export default AliExpressOAuthCallback
