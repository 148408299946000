import { useState, useEffect } from 'react'

import Button from 'components/Button/Button'
import Textbox from 'components/Textbox/Textbox'

import './InvitePartner.css'
import Dropdown from 'components/Dropdown/Dropdown'
import TextboxError from 'components/Textbox/TextboxError'

import { sendPartnerInvite } from 'utils/api/partners'

const InvitePartner = ({ onToggleInvitePartner }) => {
  const [isSupplier, setIsSupplier] = useState(true)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [error, setError] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()
    if (!email) {
      setEmailError('Please enter an email')
      return
    }

    // Convert isSupplier to boolean if string
    let isSupplierVal = isSupplier
    if (typeof isSupplier === 'string') {
      isSupplierVal = isSupplier === 'true'
    }

    setEmailError('')
    setLoading(true)
    sendPartnerInvite({
      email,
      isSupplier: isSupplierVal,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        setEmail('')
        setShowSuccess(true)
        setTimeout(() => {
          setShowSuccess(false)
          onToggleInvitePartner()
        }, 3000)
      } else {
        setError(response.data.message)
      }
    })
  }

  return (
    <div className="invite-partner-form">
      <h2>Invite a partner</h2>
      <p className="subtitle">
        Invite a partner to trade with. They will receive an email with a link
        to sign up.
      </p>
      <form onSubmit={onSubmit}>
        <div>
          {error && error !== '' && <TextboxError>{error}</TextboxError>}
          <Textbox
            label="Email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError}
          />
          <Dropdown
            label="Their Role"
            value={isSupplier}
            onChange={(e) => setIsSupplier(e.target.value)}
          >
            <option value={false}>Retailer</option>
            <option value={true}>Supplier</option>
          </Dropdown>
        </div>
        <div>
          <Button
            loading={loading}
            btnStyle={showSuccess ? 'btn-success' : 'btn-primary'}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  )
}

export default InvitePartner
