import { useState, useEffect, useRef } from 'react'

import {
  getShopifyDetails,
  updateShopifyDetails,
} from 'utils/api/internal-apps'

import './OnboardingShopifyApp.css'

const OnboardingShopifyApp = () => {
  const shopifyStoreRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [install, setInstall] = useState(null)
  const [blocker, setBlocker] = useState(null)
  const [shopifyStore, setShopifyStore] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [direction, setDirection] = useState('hendricks_to_shopify')
  const [saving, setSaving] = useState(false)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  useEffect(() => {
    getShopifyDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setInstall(response.data.install)
        setBlocker(response.data.blocker)
        setLoading(false)
        setAccessToken(
          response.data.install.settings['customAccessToken'] || ''
        )
        setShopifyStore(response.data.install.settings['storeUrl'] || '')
        setDirection(
          response.data.install.settings['direction'] || 'hendricks_to_shopify'
        )
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  const onUpdate = (e, body) => {
    e.preventDefault()
    setSaving(true)
    updateShopifyDetails(viewToken, body).then((response) => {
      setSaving(false)
      if (response.success) {
        console.log('Settings saved successfully')
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h2 className="onboarding-section-title">Shopify Setup</h2>
      <p className="text-muted">
        Please follow the instructions below to setup the Shopify app.
      </p>
      {blocker && (
        <div className="alert alert-danger">
          <div className="alert-message">{blocker}</div>
        </div>
      )}
      <br />
      <div className="shopify-section">
        <label className="shopify-item-title">Shopify Store</label>
        <div className="myshopify-input-wrapper">
          <input
            type="text"
            className="myshopify-input"
            autoComplete="off"
            placeholder="sample"
            value={shopifyStore}
            onChange={(e) => {
              setShopifyStore(e.target.value)
              onUpdate(e, { settings: { storeUrl: e.target.value } })
            }}
            ref={shopifyStoreRef}
          />
          <label
            onClick={(e) => {
              e.preventDefault()
              shopifyStoreRef.current.focus()
            }}
          >
            .myshopify.com
          </label>
        </div>
        <p>
          <a
            href="https://docs.hendricks.so/guides/where-do-i-find-my-shopify-store-url"
            target="_blank"
          >
            Where do I find my Shopify store URL?
          </a>
        </p>
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>
      <div className="shopify-section">
        <label className="shopify-item-title">Access Token</label>
        <input
          type="text"
          className="form-control"
          placeholder="Custom Access Token"
          autoComplete="off"
          value={accessToken}
          onChange={(e) => {
            setAccessToken(e.target.value)
            onUpdate(e, { settings: { customAccessToken: e.target.value } })
          }}
        />
        <p>
          <a
            href="https://docs.hendricks.so/guides/how-do-i-create-my-shopify-access-token"
            target="_blank"
          >
            How do I create my Shopify access token?
          </a>
        </p>
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>
      <div className="shopify-section">
        <label className="shopify-item-title">Direction</label>
        <select
          className="form-control"
          value={direction}
          onChange={(e) => {
            setDirection(e.target.value)
            onUpdate(e, { settings: { direction: e.target.value } })
          }}
        >
          <option value="retailer">Retailer</option>
          <option value="supplier">Supplier</option>
        </select>
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>
    </div>
  )
}

export default OnboardingShopifyApp
