import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import Button from 'components/Button/Button'

import { getProductById, selectProduct } from 'utils/api/products'
import { displayDollarAmount } from 'utils/money'

import './ViewProductPage.css'
import './ViewProductLoadingPage.css'
import Modal from 'components/Modal/Modal'
import AdjustInventory from './AdjustInventory'
import { ChevronLeft } from 'lucide-react'
import { countries, flags } from 'utils/countries'

const getVariantThumbnail = (images, variant) => {
  if (!images) {
    return ''
  }
  // images.$.relatedVariantIds matches variant.variantId (not .id)
  for (let image of images) {
    if (image.relatedVariantIds.includes(variant.variantId)) {
      return image.src
    }
  }
  return ''
}

const VariantRow = ({ variant, product }) => {
  const [showInventory, setShowInventory] = useState(false)

  const onToggleInventory = () => {
    setShowInventory(!showInventory)
  }

  const thumbnail = getVariantThumbnail(product.images, variant)

  return (
    <>
      <tr>
        <td className="variant-details-cell">
          {thumbnail && thumbnail !== '' ? (
            <img src={thumbnail} alt="" className="variant-thumbnail" />
          ) : (
            <img
              src="https://placehold.co/400"
              alt=""
              className="variant-thumbnail"
            />
          )}
          <div className="variant-details-inner-cell">
            <span className="variant-title">{variant.title}</span>
            <span className="variant-sku">{variant.sku}</span>
            <span className="variant-barcode">
              {variant.barcode && variant.barcode !== '' && (
                <>
                  <span className="barcode-type">({variant.barcodeType})</span>
                  <span className="barcode-value">{variant.barcode}</span>
                </>
              )}
            </span>
          </div>
        </td>
        <td>
          <span className="variant-quantity">
            {variant.inventoryQuantity || '0'}
          </span>
          <button className="show-by-location-btn" onClick={onToggleInventory}>
            {showInventory ? 'Hide By Locations' : 'Show By Location'}
          </button>
        </td>
        {!product.isSupplier && (
          <td>
            {variant.basePriceByQuantities &&
            Object.keys(variant.basePriceByQuantities).length > 0 ? (
              <span>
                {Object.keys(variant.basePriceByQuantities).map((quantity) => (
                  <div>
                    <span className="price-quantity">{quantity} or more:</span>
                    <span className="price-amount">
                      {displayDollarAmount(
                        variant.basePriceByQuantities[quantity].amount,
                        variant.basePriceByQuantities[quantity].currency
                      )}
                    </span>
                  </div>
                ))}
              </span>
            ) : (
              '(Not Set)'
            )}
          </td>
        )}
        <td>
          {variant.retailPrice ? (
            <span className="variant-price">
              {displayDollarAmount(
                variant.retailPrice.amount,
                variant.retailPrice.currency
              )}
            </span>
          ) : (
            '(Not Set)'
          )}
        </td>
      </tr>
      {showInventory && (
        <>
          {variant.inventoryByLocation.map((inventory) => (
            <tr>
              <td></td>
              <td>
                <div className="variant-warehouse-list-item">
                  <button className="location-name">{inventory.name}:</button>
                  <p className="location-quantity">{inventory.quantity || 0}</p>
                </div>
              </td>
              {!product.isSupplier && <td></td>}
              <td></td>
            </tr>
          ))}
        </>
      )}
    </>
  )
}

const ProductLoadingPage = () => (
  <Page>
    <PageTitleRow>
      <div className="view-product-title-col">
        <div className="product-title-loading"></div>
      </div>
    </PageTitleRow>
    <div className="row">
      <div className="col-8">
        <div className="product-page-panel">
          <div className="product-section-title-loading"></div>
          <div className="product-description-loading"></div>
        </div>
        <div className="product-page-panel">
          <div className="product-section-title-loading"></div>
          <table className="table variants-table">
            <tr>
              <td style={{ width: '33.33%' }}>
                <div className="variant-title-loading"></div>
                <div className="variant-sku-loading"></div>
                <div className="variant-barcode-loading"></div>
              </td>
              <td>
                <div className="variant-quantity-loading"></div>
              </td>
              <td>
                <div className="variant-price-loading"></div>
              </td>
              <td>
                <div className="variant-price-loading"></div>
              </td>
            </tr>
            <tr>
              <td style={{ width: '33.33%' }}>
                <div className="variant-title-loading"></div>
                <div className="variant-sku-loading"></div>
                <div className="variant-barcode-loading"></div>
              </td>
              <td>
                <div className="variant-quantity-loading"></div>
              </td>
              <td>
                <div className="variant-price-loading"></div>
              </td>
              <td>
                <div className="variant-price-loading"></div>
              </td>
            </tr>
            <tr>
              <td style={{ width: '33.33%' }}>
                <div className="variant-title-loading"></div>
                <div className="variant-sku-loading"></div>
                <div className="variant-barcode-loading"></div>
              </td>
              <td>
                <div className="variant-quantity-loading"></div>
              </td>
              <td>
                <div className="variant-price-loading"></div>
              </td>
              <td>
                <div className="variant-price-loading"></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </Page>
)

const ViewProductPage = () => {
  let navigate = useNavigate()
  const [showModal, setShowModal] = useState('')
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [product, setProduct] = useState({})
  const [variants, setVariants] = useState([])
  const [selecting, setSelecting] = useState(false)

  const fetchProduct = () => {
    getProductById(id, {
      includeVariants: true,
    }).then((response) => {
      if (response.success) {
        setProduct(response.data.product)
        setVariants(response.data.variants || [])
        setLoading(false)
      } else {
        window.alert('Error fetching product')
      }
    })
  }

  const onSelect = (e) => {
    e.preventDefault()

    setSelecting(true)
    selectProduct(product.id, {}).then((response) => {
      if (response.success) {
        fetchProduct()
        setSelecting(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onDeselect = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    fetchProduct()
  }, [])

  if (loading) {
    return <ProductLoadingPage />
  }

  return (
    <Page>
      <PageTitleRow>
        <div className="view-product-title-col">
          <h1>{product.title}</h1>
          <button className="back-btn" onClick={(e) => navigate('/products')}>
            <ChevronLeft />
            Back to Products
          </button>
        </div>
        <div className="view-product-actions-col">
          {product.isSelectable && (
            <>
              {product.selected ? (
                <Button
                  disabled={product.blocked}
                  onClick={onDeselect}
                  loading={selecting}
                >
                  Deselect
                </Button>
              ) : (
                <>
                  <Button
                    disabled={product.blocked}
                    onClick={onSelect}
                    loading={selecting}
                  >
                    Select
                  </Button>
                  {product.blocked && (
                    <div className="alert alert-danger">
                      <div className="alert-message">
                        {product.blockedByReason}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <div className="product-page-panel">
                <h2 className="bottom-title-spacer">Description</h2>
                <p className="product-page-description">
                  {product && product.description ? (
                    product.description
                  ) : (
                    <i>No Description Set</i>
                  )}
                </p>
              </div>
              <div className="product-page-panel">
                <h2 className="bottom-title-spacer">Variants</h2>
                <table className="table w-100">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Quantity</th>
                      {!product.isSupplier && <th>Your Price</th>}
                      <th>Retail Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variants.length > 0 ? (
                      variants.map((variant) => (
                        <VariantRow variant={variant} product={product} />
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No variants found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>
                  {/* <button
                    className="btn btn-outline-secondary"
                    style={{ marginRight: 5 }}
                  >
                    Edit Variants
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    style={{ marginRight: 5 }}
                  >
                    Add Variant
                  </button> */}
                  <button
                    className="btn btn-outline-secondary"
                    style={{ marginRight: 5 }}
                    onClick={(e) => setShowModal('adjust-inventory')}
                  >
                    Adjust Stock
                  </button>
                </div>
              </div>
              <div className="product-page-panel">
                <h2 className="bottom-title-spacer">Images</h2>
                <div className="product-images">
                  {(product.images || []).map((image) => (
                    <button className="product-image-btn">
                      <img src={image.src} alt="" className="product-image" />
                    </button>
                  ))}
                </div>
              </div>
              <div className="product-page-panel">
                <h2 className="bottom-title-spacer">Attributes</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="product-page-panel">
            <div className="card">
              <div className="card-body">
                <div>
                  {product.active ? (
                    <div className="badge badge-subtle-success product-status-tag">
                      Active
                    </div>
                  ) : (
                    <div className="badge badge-subtle-danger product-status-tag product-status-inactive">
                      Inactive
                    </div>
                  )}
                  {!product.isSupplier && product.selected ? (
                    <div className="badge badge-subtle-primary product-status-tag product-status-selected">
                      Selected
                    </div>
                  ) : !product.isSupplier ? (
                    <div className="badge badge-subtle-secondary product-status-tag product-status-not-selected">
                      Not Selected
                    </div>
                  ) : null}
                </div>
                <div className="product-page-divider"></div>
                <div>
                  <p className="product-field-title">Brand</p>
                  <p className="product-field-value">
                    {product.brand || '(Not set)'}
                  </p>
                </div>
                {!product.isSupplier && (
                  <div>
                    <p className="product-field-title">Partner</p>
                    <p className="product-field-value">
                      {product.partnerName || ''}
                    </p>
                  </div>
                )}

                {product.isSupplier && (
                  <>
                    <div className="product-page-divider"></div>
                    <div>
                      <p className="product-field-title">Source</p>
                      <p className="product-field-value">
                        {product.source.platform || '(Not Set)'}
                      </p>
                    </div>
                    <div>
                      <p className="product-field-title">Source Id</p>
                      <p className="product-field-value">
                        {product.source.id || '(Not Set)'}
                      </p>
                    </div>
                  </>
                )}

                <div className="product-page-divider"></div>

                {product.createdAt && (
                  <div>
                    <p className="product-field-title">Created</p>
                    <p className="product-field-value">
                      {new Date(product.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                )}
                {product.updatedAt && (
                  <div>
                    <p className="product-field-title">Last Updated</p>
                    <p className="product-field-value">
                      {new Date(product.updatedAt).toLocaleDateString()}
                    </p>
                  </div>
                )}

                {product.tags && product.tags.length > 0 && (
                  <>
                    <div className="product-page-divider"></div>

                    <div>
                      <p className="product-field-title">Tags</p>
                    </div>
                  </>
                )}

                {product.googleProductCategory.code != 0 && (
                  <>
                    <div className="product-page-divider"></div>

                    <div>
                      <p className="product-field-title">
                        Google product Category
                      </p>
                    </div>
                  </>
                )}

                {/* <div className="product-page-divider"></div>

                <div>
                  <p className="product-field-title">Options</p>
                </div> */}

                <div className="product-page-divider"></div>

                <div>
                  <p className="product-field-title">Ship To Locations</p>
                  {(product.shipToLocations || []).length > 0 ? (
                    <>
                      {product.shipToLocations.map((location) => (
                        <span className="badge rounded-pill badge-subtle-secondary">
                          <span style={{ marginRight: 5 }}>
                            {flags[location.country]}
                          </span>
                          {countries[location.country]}
                        </span>
                      ))}
                    </>
                  ) : (
                    <span>🌎 Everywhere</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal === 'adjust-inventory' ? (
        <Modal toggle={(e) => setShowModal('')} hidePanel size="large">
          <AdjustInventory variants={variants} />
        </Modal>
      ) : null}
    </Page>
  )
}

export default ViewProductPage
