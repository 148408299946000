import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'

import InvitePartner from './InvitePartner'
import PendingInvites from './PendingInvites'
import { listPartners } from 'utils/api/partners'

import './ListPartnersPage.css'
import TextboxError from 'components/Textbox/TextboxError'
import Modal from 'components/Modal/Modal'
import { Plus } from 'lucide-react'

const SinglePartner = ({ partner }) => {
  let navigate = useNavigate()
  const onView = () => {
    console.log('View partner', partner)
    navigate(`/partners/${partner.id}`)
  }
  return (
    <tr>
      <td>
        <img src={'/fav_icon.png'} width="32" height="32" alt={partner.name} />
      </td>
      <td>{partner.name}</td>
      <td>
        {partner.pricingId && partner.pricingName !== '' ? (
          <Link to={`/pricing/${partner.pricingId}`}>
            {partner.pricingName}
          </Link>
        ) : partner.pricingId && partner.pricingName === '' ? (
          <Link to={`/pricing/${partner.pricingId}`}>View pricing</Link>
        ) : (
          <span>No pricing</span>
        )}
      </td>
      <td>
        {partner.active ? (
          <span class="badge badge-subtle-success">Active</span>
        ) : (
          <span class="badge badge-subtle-danger">Deleted</span>
        )}
      </td>
      <td>
        <button type="button" className="btn btn-light" onClick={onView}>
          View
        </button>
      </td>
    </tr>
  )
}

const LoadingPartner = () => {
  return (
    <div className="partner-list-item">
      <div className="partner-logo">
        <div className="placeholder-img"></div>
      </div>
      <div className="partner-details">
        <div className="placeholder-partner-name"></div>
      </div>
    </div>
  )
}

const LoadingPartners = () => (
  <>
    <LoadingPartner />
    <LoadingPartner />
    <LoadingPartner />
    <LoadingPartner />
    <LoadingPartner />
    <LoadingPartner />
  </>
)

const EmptyPartners = () => (
  <div className="empty-partners">
    <p>No partners found</p>
  </div>
)

const ListPartnersPage = () => {
  const [partners, setPartners] = useState([])
  const [loading, setLoading] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')
  const [showInvitePartner, setShowInvitePartner] = useState(false)

  const onToggleInvitePartner = () => {
    setShowInvitePartner(!showInvitePartner)
  }

  const fetchPartners = () => {
    listPartners({
      page,
      name: search,
    }).then((response) => {
      if (response.success) {
        setPartners(response.data.partners || [])
      }
      setLoading(false)
      setInitialLoad(false)
    })
  }

  useEffect(() => {
    fetchPartners()
  }, [])

  useEffect(() => {
    fetchPartners()
  }, [page, search])

  return (
    <Page>
      <h1 class="h3 mb-3">Partners</h1>

      <div class="row">
        <div class="col-xl-8">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6 mb-2 mb-md-0">
                  <div class="input-group input-group-search">
                    <input
                      type="text"
                      class="form-control"
                      id="datatables-customers-search"
                      placeholder="Search partners…"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />
                    <button class="btn" type="button">
                      <i class="align-middle" data-lucide="search"></i>
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-sm-end">
                    {/* <button type="button" class="btn btn-light btn-lg me-2"><i data-lucide="download"></i> Export</button> */}
                    <button
                      type="button"
                      class="btn btn-primary btn-lg"
                      onClick={onToggleInvitePartner}
                    >
                      <Plus /> Invite Partner
                    </button>
                  </div>
                </div>
              </div>
              <table id="datatables-customers" class="table w-100">
                <thead>
                  <tr>
                    <th class="text-start"></th>
                    <th>Company</th>
                    <th>Pricing</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {partners && partners.length > 0 ? (
                    <>
                      {partners.map((partner) => (
                        <SinglePartner key={partner.id} partner={partner} />
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="5">No Partners</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <PendingInvites fetchPartners={fetchPartners} />
        </div>
      </div>
      {showInvitePartner && (
        <Modal toggle={onToggleInvitePartner}>
          <InvitePartner
            onToggleInvitePartner={onToggleInvitePartner}
            fetchPartners={fetchPartners}
          />
        </Modal>
      )}
    </Page>
  )
}

export default ListPartnersPage
