//
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckIcon, XIcon } from 'lucide-react'

import SharedOnboardingPage from './SharedOnboardingPage'

import { getMe } from 'utils/api/users'

import {
  listPartners,
  listPartnerInvites,
  sendPartnerInvite,
  acceptPartnerInvite,
  declinePartnerInvite,
} from 'utils/api/partners'
import OnboardingPageNextButtons from './OnboardingPageNextButtons'

import './OnboardingPartnersPage.css'

const PendingInvite = ({ invite, fetchPendingInvites }) => {
  const [loading, setLoading] = useState(false)

  const onAccept = (e) => {
    e.preventDefault()

    setLoading(true)
    acceptPartnerInvite(invite.id).then((response) => {
      setLoading(false)
      if (response.success) {
        fetchPendingInvites()
        // fetchPartners()
      }
    })
  }

  const onReject = (e) => {
    e.preventDefault()

    setLoading(true)
    declinePartnerInvite(invite.id).then((response) => {
      setLoading(false)
      if (response.success) {
        fetchPendingInvites()
        // fetchPartners()
      }
    })
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="pending-invite-wrapper">
          <div>
            <h5 className="card-title">{invite.senderOrgName}</h5>
            {invite.isSupplier ? (
              <span className="badge badge-subtle-primary">
                They are the Supplier
              </span>
            ) : (
              <span className="badge badge-subtle-primary">
                They are the Retailer
              </span>
            )}
          </div>
          <div className="pending-invite-actions">
            <button
              className="btn btn-success"
              disabled={loading}
              onClick={onAccept}
            >
              <CheckIcon size={16} />
              Accept
            </button>
            <button
              className="btn btn-danger"
              disabled={loading}
              onClick={onReject}
            >
              <XIcon size={16} />
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const Invites = ({
  loading,
  sentInvites,
  pendingInvites,
  fetchPendingInvites,
}) => {
  if (loading) {
    return null
  }

  if (pendingInvites.length === 0 && sentInvites.length === 0) {
    return (
      <>
        <p>No received invites found.</p>
        <br />
      </>
    )
  }

  console.log('pendingInvites', pendingInvites)

  return (
    <>
      <p style={{ marginBottom: 10 }}>
        <strong>Invites</strong>
      </p>
      {pendingInvites.map((invite) => (
        <PendingInvite
          key={invite.id}
          invite={invite}
          fetchPendingInvites={fetchPendingInvites}
        />
      ))}
      <br />
    </>
  )
}

const InviteForm = ({ fetchSentInvites, company }) => {
  let currentOrgEstimatedRole = '' // Default to Retailer
  let partnerRole = false
  if (
    company &&
    company.onboarding.isRetailer &&
    !company.onboarding.isSupplier
  ) {
    currentOrgEstimatedRole = 'retailer' // Retailer
    partnerRole = true // Supplier
  } else if (
    company &&
    company.onboarding.isSupplier &&
    !company.onboarding.isRetailer
  ) {
    currentOrgEstimatedRole = 'supplier' // Supplier
    partnerRole = false // Retailer
  }
  console.log('currentOrgEstimatedRole', currentOrgEstimatedRole, partnerRole)
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(partnerRole) // Do the opposite of the current org's role
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    setSending(true)
    setError('')
    setSuccess(false)

    sendPartnerInvite({
      email,
      role,
    }).then((response) => {
      setSending(false)
      if (response.success) {
        setSuccess(true)

        fetchSentInvites()

        setTimeout(() => {
          setEmail('')
          setRole(false)
          setSuccess(false)
        }, 3000)
      } else {
        setError(response.data.message)
      }
    })
  }
  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="inviteEmail">Partner's Email</label>
        <input
          type="email"
          className="form-control"
          id="inviteEmail"
          aria-describedby="inviteEmailHelp"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <small id="inviteEmailHelp" className="form-text text-muted">
          We'll send an invite to this email address.
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="inviteRole">Their Role</label>
        <select
          className="form-control"
          id="inviteRole"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value={true}>Supplier</option>
          <option value={false}>Retailer</option>
        </select>
        {currentOrgEstimatedRole === 'retailer' ? (
          <small id="inviteEmailHelp" className="form-text text-muted">
            Based on what we know about you, you are most likely the retailer,
            and <strong>their role is the supplier.</strong>
          </small>
        ) : currentOrgEstimatedRole == 'supplier' ? (
          <small id="inviteEmailHelp" className="form-text text-muted">
            Based on what we know about you, you are most likely the supplier,
            and <strong>their role is the retailer.</strong>
          </small>
        ) : null}
      </div>
      {success ? (
        <button type="submit" className="btn btn-success" disabled={true}>
          Sent!
        </button>
      ) : (
        <button type="submit" className="btn btn-primary" disabled={sending}>
          Invite
        </button>
      )}
      {error && error !== '' && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
    </form>
  )
}

const OnboardingPartnersPage = () => {
  let navigate = useNavigate()
  const [loadingPartners, setLoadingPartners] = useState(true)
  const [partners, setPartners] = useState([])
  const [loadingPendingInvites, setLoadingPendingInvites] = useState(true)
  const [pendingInvites, setPendingInvites] = useState([])
  const [loadingSentInvites, setLoadingSentInvites] = useState(true)
  const [sentInvites, setSentInvites] = useState([])
  const [loadingCompany, setLoadingCompany] = useState(true)
  const [company, setCompany] = useState(null)

  const fetchPartners = () => {
    listPartners({
      page: 1,
      limit: 25,
    }).then((response) => {
      if (response.success) {
        setPartners(response.data.partners)
      }
      setLoadingPartners(false)
    })
  }

  const fetchPendingInvites = () => {
    listPartnerInvites({
      page: 1,
      limit: 25,
    }).then((response) => {
      if (response.success) {
        setPendingInvites(response.data.invites || [])
      }
      setLoadingPendingInvites(false)
    })
  }

  const fetchSentInvites = () => {
    // TODO
    setLoadingSentInvites(false)
  }

  useEffect(() => {
    getMe({}).then((response) => {
      if (response.success) {
        setLoadingCompany(false)
        setCompany(response.data.company)
      }
    })
    fetchPartners()
    fetchPendingInvites()
    fetchSentInvites()
  }, [])

  const onNext = (e) => {
    e.preventDefault()
    if (company.onboarding.isSupplier) {
      navigate('/onboarding/discovery')
      return
    }
    navigate('/onboarding/syncing')
  }

  if (loadingCompany || loadingPartners || loadingPendingInvites) {
    return <div>Loading...</div>
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={6}>
      <h2 className="onboarding-section-title">Partners</h2>
      <p className="text-muted">
        Invite any trading partners you'd like to connect with on the platform.
      </p>
      <br />
      <InviteForm fetchSentInvites={fetchSentInvites} company={company} />
      <hr />
      <Invites
        loading={loadingPendingInvites || loadingSentInvites}
        pendingInvites={pendingInvites}
        sentInvites={sentInvites}
        fetchPendingInvites={fetchPendingInvites}
      />

      {!loadingPartners && partners.length > 0 && (
        <>
          <p>
            <strong>Existing Partners</strong>
          </p>
          <ul>
            {partners.map((partner) => (
              <li key={partner.id}>{partner.name}</li>
            ))}
          </ul>
        </>
      )}
      <OnboardingPageNextButtons>
        <button className="btn btn-primary" onClick={onNext}>
          Next
        </button>
      </OnboardingPageNextButtons>
    </SharedOnboardingPage>
  )
}

export default OnboardingPartnersPage
