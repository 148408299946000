import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'

import App from 'containers/App/App'
import PrivateRoute from 'containers/App/PrivateRoute'

import LogoutPage from 'containers/Logout/LogoutPage'
import DashboardPage from 'containers/Dashboard/DashboardPage'
import SignUpPage from 'containers/SignUp/SignUpPage'
import SignInPage from 'containers/SignIn/SignInPage'
import SettingsProfilePage from 'containers/Settings/Profile/SettingsProfilePage'
import SettingsCompanyPage from 'containers/Settings/Company/SettingsCompanyPage'
import SettingsBillingPage from 'containers/Settings/Billing/SettingsBillingPage'
import SettingsCreateCompanyPage from 'containers/Settings/CreateCompany/SettingsCreateCompanyPage'
import SettingsAPIPage from 'containers/Settings/API/SettingsAPIPage'
import SettingsDeveloperPage from 'containers/Settings/Developer/SettingsDeveloperPage'

import CreateCompanyPage from 'containers/CreateCompany/CreateCompanyPage'
import AddInstallsPage from 'containers/Dashboard/AddInstallsPage'
import InstallDetailsPage from 'containers/Installs/Details/InstallDetailsPage'
import InstallSettingsPage from 'containers/Installs/Details/Settings/InstallSettingsPage'
import InstallSetupPage from 'containers/Installs/Details/Setup/InstallSetupPage'
import InstallSyncEventsPage from 'containers/Installs/Details/Events/InstallSyncEventsPage'
import InstallSyncEventsForProcesses from 'containers/Installs/Details/Events/InstallSyncEventsForProcesses'
import InstallsSupportPage from 'containers/Installs/Details/Support/InstallsSupportPage'
import InstallsNewSupportPage from 'containers/Installs/Details/Support/InstallsNewSupportPage'
import InstallSupportDetailsPage from 'containers/Installs/Details/Support/InstallSupportDetailsPage'
import InstallProcessesPage from 'containers/Installs/Details/Processes/InstallProcessesPage'
import InstallEditProcessesPage from 'containers/Installs/Details/Processes/InstallEditProcessesPage'
import EDIRetrievalsSchemaPage from 'containers/Installs/Details/EDIRetrievals/EDIRetrievalsSchemaPage'

import SFTPListUsersPage from 'containers/SFTPServer/ListUsers/SFTPListUsersPage'
import SFTPListFilesPage from 'containers/SFTPServer/ListFiles/SFTPListFilesPage'

import NotFoundPage from 'containers/NotFound/NotFoundPage'

import ListPartnersPage from 'containers/Partners/List/ListPartnersPage'
import AppStoreHomePage from 'containers/AppStore/AppStoreHomePage'
import ManageInstallPage from 'containers/Apps/Installs/ManageInstallPage'
import AppsViewerPage from 'containers/Apps/AppsViewer/AppsViewerPage'

import ListProductsPage from 'containers/Products/List/ListProductsPage'
import ViewProductPage from 'containers/Products/View/ViewProductPage'
import ListLocationsPage from 'containers/Products/ListLocations/ListLocationsPage'
import ViewLocationPage from 'containers/Products/ViewLocation/ViewLocationPage'

import PricingListPage from 'containers/Pricing/List/PricingListPage'
import PricingViewPage from 'containers/Pricing/View/PricingViewPage'

import DeveloperMainPage from 'containers/Developer/List/DeveloperMainPage'
import DeveloperCreatePage from 'containers/Developer/Create/DeveloperCreatePage'

import ListNotificationsPage from 'containers/Notifications/List/ListNotificationsPage'

import ViewPartnerPage from 'containers/Partners/View/ViewPartnerPage'

import ListOrdersPage from 'containers/Orders/List/ListOrdersPage'
import OrdersViewPage from 'containers/Orders/View/OrdersViewPage'
import ListOrderRulesPage from 'containers/Orders/List/ListOrderRulesPage'
import ViewOrderRulesPage from 'containers/Orders/Rules/ViewOrderRulesPage'
import CreateOrderPage from 'containers/Orders/Create/CreateOrderPage'

import EDIMainPage from 'internal-apps/EDI/EDIMainPage'
import EDIBetaPage from 'internal-apps/EDIBeta/EDIBetaPage'
import EDIDemoPage from 'internal-apps/EDIDemo/EDIDemoPage'
import OnboardingShopifyApp from 'internal-apps/Shopify/OnboardingShopifyApp'


import SettingsInvoicingPage from 'containers/Settings/Invoicing/SettingsInvoicingPage'

import OnboardingQuestionairePage from 'containers/Onboarding/OnboardingQuestionairePage'
import OnboardingPlansPage from 'containers/Onboarding/OnboardingPlansPage'
import OnboardingAppsPage from 'containers/Onboarding/OnboardingAppsPage'
import OnboardingAppsSetupPage from 'containers/Onboarding/OnboardingAppsSetupPage'
import OnboardingSyncingPage from 'containers/Onboarding/OnboardingSyncingPage'
import OnboardingInvoicingPage from 'containers/Onboarding/OnboardingInvoicingPage'
import OnboardingPartnersPage from 'containers/Onboarding/OnboardingPartnersPage'
import OnboardingDiscoveryPage from 'containers/Onboarding/OnboardingDiscoveryPage'
import OnboardingPricingPage from 'containers/Onboarding/OnboardingPricingPage'
import OnboardingCompletedPage from 'containers/Onboarding/OnboardingCompletedPage'

import OnboardingWooCommerceApp from 'internal-apps/WooCommerce/OnboardingWooCommerceApp'
import AliExpressOAuthCallback from 'containers/OAuth/AliExpress/AliExpressOAuthCallback'
import AliExpressPage from 'internal-apps/AliExpress/AliExpressPage'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App>
      <Routes>
        <Route
          path="/internal-apps/aliexpress"
          element={
            <PrivateRoute>
              <AliExpressPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/oauth/v1/aliexpress"
          element={
            <PrivateRoute>
              <AliExpressOAuthCallback />
            </PrivateRoute>
          }
        />
        <Route
          path="/internal-apps/woocommerce/onboarding"
          element={
            <OnboardingWooCommerceApp />
          }
        />
        <Route
          path="/internal-apps/woocommerce"
          element={
            <OnboardingWooCommerceApp />
          }
        />
        <Route
          path="/onboarding/completed"
          element={
            <PrivateRoute>
              <OnboardingCompletedPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/pricing"
          element={
            <PrivateRoute>
              <OnboardingPricingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/syncing"
          element={
            <PrivateRoute>
              <OnboardingSyncingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/discovery"
          element={
            <PrivateRoute>
              <OnboardingDiscoveryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/partners"
          element={
            <PrivateRoute>
              <OnboardingPartnersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/invoicing"
          element={
            <PrivateRoute>
              <OnboardingInvoicingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/app-setup/:appId/:installId/:viewToken"
          element={
            <PrivateRoute>
              <OnboardingAppsSetupPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/apps"
          element={
            <PrivateRoute>
              <OnboardingAppsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/plans"
          element={
            <PrivateRoute>
              <OnboardingPlansPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/"
          element={
            <PrivateRoute>
              <OnboardingQuestionairePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/internal-apps/shopify/onboarding"
          element={
            <OnboardingShopifyApp />
          }
        />
        <Route
          path="/internal-apps/edi-demo"
          element={
            <EDIDemoPage />
          }
        />
        <Route
          path="/internal-apps/edi-beta/:ediRetailerId"
          element={
            <EDIBetaPage />
          }
        />
        <Route
          path="/internal-apps/edi/:ediRetailerId"
          element={<EDIMainPage />}
        />
        <Route
          path="/orders/new"
          element={
            <PrivateRoute>
              <CreateOrderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/rules/:id"
          element={
            <PrivateRoute>
              <ViewOrderRulesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/rules"
          element={
            <PrivateRoute>
              <ListOrderRulesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <PrivateRoute>
              <OrdersViewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <ListOrdersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <ListNotificationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pricing/:id"
          element={
            <PrivateRoute>
              <PricingViewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <PrivateRoute>
              <PricingListPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/apps-viewer/:appId/:installId/:viewToken"
          element={
            <PrivateRoute>
              <AppsViewerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/apps/:appId/:installId"
          element={
            <PrivateRoute>
              <ManageInstallPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/developer/create"
          element={
            <PrivateRoute>
              <DeveloperCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/developer"
          element={
            <PrivateRoute>
              <DeveloperMainPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/locations/:id"
          element={
            <PrivateRoute>
              <ViewLocationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/locations"
          element={
            <PrivateRoute>
              <ListLocationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/products/:id"
          element={
            <PrivateRoute>
              <ViewProductPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute>
              <ListProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/app-store"
          element={
            <PrivateRoute>
              <AppStoreHomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners/:id"
          element={
            <PrivateRoute>
              <ViewPartnerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners"
          element={
            <PrivateRoute>
              <ListPartnersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/sftp/:username"
          element={
            <PrivateRoute>
              <SFTPListFilesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/sftp"
          element={
            <PrivateRoute>
              <SFTPListUsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/developer"
          element={
            <PrivateRoute>
              <SettingsDeveloperPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/api"
          element={
            <PrivateRoute>
              <SettingsAPIPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/invoicing"
          element={
            <PrivateRoute>
              <SettingsInvoicingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/create-company"
          element={
            <PrivateRoute>
              <SettingsCreateCompanyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/billing"
          element={
            <PrivateRoute>
              <SettingsBillingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/company"
          element={
            <PrivateRoute>
              <SettingsCompanyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/"
          element={
            <PrivateRoute>
              <SettingsProfilePage />
            </PrivateRoute>
          }
        />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/create-company" element={<CreateCompanyPage />} />
        <Route
          path="/add"
          element={
            <PrivateRoute>
              <AddInstallsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </App>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
