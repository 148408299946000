import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Panel from 'components/Panel/Panel'
import Logo from 'components/Logo/Logo'
import Button from 'components/Button/Button'
import Textbox from 'components/Textbox/Textbox'

import { signUp } from 'utils/api/login'

import { setEmail as setEmailInCookies, setAuthToken } from 'utils/cookies'

import 'containers/SignIn/SignInPage.css'

const hasSpecialCharacters = (str) => {
  return 5 <= str.replace(/[a-zA-Z]/g, '').length
}

const SignUpPage = () => {
  let navigate = useNavigate()

  // Get query params
  const queryParams = new URLSearchParams(window.location.search)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(queryParams.get('email') || '')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [firstName, setFirstName] = useState(queryParams.get('firstName') || '')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(queryParams.get('lastName') || '')
  const [redirect, setRedirect] = useState('')
  const [inviteCode, setInviteCode] = useState(
    queryParams.get('inviteCode') || ''
  )

  const onSubmit = (e) => {
    e.preventDefault()
    setPasswordError('')
    setEmailError('')
    setFirstNameError('')

    if (email === '') {
      setEmailError('Email is required')
      return
    }
    if (password === '') {
      setPasswordError('Password is required')
      return
    }
    if (firstName === '') {
      setFirstNameError('Name is required')
      return
    }
    if (!hasSpecialCharacters(password)) {
      setPasswordError('Your password requires 5 special characters')
      return
    }

    setLoading(true)
    signUp({
      firstName,
      lastName,
      email,
      password,
      inviteCode,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        const { data } = response
        console.log('data', data)
        setAuthToken(data.session.id)
        console.log('email', email)
        setEmailInCookies(email)
        // Delete email from query params
        queryParams.delete('email')
        queryParams.delete('firstName')
        queryParams.delete('lastName')
        queryParams.delete('disableEmail')
        const url = `/create-company?${queryParams.toString()}`
        setRedirect(url)
        return
      } else {
        setPasswordError(response.data.message)
      }
    })
  }

  if (redirect !== '') {
    console.log('redirect', redirect)
    navigate(redirect)
  }

  return (
    <div className="container-fluid">
      <div className="row sign-in-row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <Panel>
            <br />
            <Logo size="md" />
            <br />
            <form onSubmit={onSubmit}>
              <div>
                <div style={{ display: 'flex' }}>
                  <Textbox
                    value={firstName}
                    placeholder="First"
                    onChange={(e) => setFirstName(e.target.value)}
                    error={firstNameError}
                    textboxStyle={{ width: '50%' }}
                  />
                  <Textbox
                    value={lastName}
                    placeholder="Last"
                    onChange={(e) => setLastName(e.target.value)}
                    textboxStyle={{ width: '50%' }}
                  />
                </div>
                <Textbox
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailError}
                  readOnly={queryParams.get('disableEmail') === 'true'}
                />
                <Textbox
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordError}
                  type="password"
                />
                {/* <Textbox
                  value={inviteCode}
                  placeholder="Invite Code"
                  onChange={(e) => setInviteCode(e.target.value)}
                /> */}
              </div>
              <div className="sign-in-button-wrapper">
                <Button loading={loading}>Sign Up</Button>
              </div>
            </form>
            <div>
              <p className="sign-in-other-text">
                Already have an account? <a href="/signin">Sign In</a>
              </p>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default SignUpPage
