import { get, post, patch } from './general'
import { getCompanyId } from '../cookies'

//   export const createProductCatalog = (body) => {
//     return post(
//       '/product-catalogs',
//       {},
//       body,
//     )
//   }

export const listProducts = (params) => {
  return get(`/products`, {}, params)
}

export const getProductById = (productId, params) => {
  return get(`/products/${productId}`, {}, params)
}

export const selectProduct = (id, body) => {
  return post(`/products/${id}/select`, {}, body)
}

export const addLocationToVariant = (productId, variantId, body) => {
  return post(`/products/${productId}/variants/${variantId}/locations`, {}, body)
}

export const updateLocationToVariant = (productId, variantId, locationId, body) => {
  return patch(`/products/${productId}/variants/${variantId}/locations/${locationId}`, {}, body)
}


export const listVariants = (productId, params) => {
  return get(`/products/${productId}/variants`, {}, params)
}